@import '@watersport/ui-react-next/dist/assets/css/tailwind.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Ensure the Inter font is applied globally */
  body,
  html {
    font-family: 'Inter', sans-serif;
    height: 100%;
    margin: 0;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  /* Fix for unexpected behavior in iPhone Safari browser */
  :root {
    --vh: 100vh;
  }

  .h-screen {
    height: calc(var(--vh, 1vh) * 100);
  }

  /* Disable pull-to-refresh */
  html,
  body {
    overscroll-behavior: none;
  }
}

/* Component and utility styles */
#root {
  height: 100%;
  background-color: #e5e7eb;
}

.range-slider {
  background-color: #19a5bd;
}

.range-slider-0,
.range-slider-2 {
  background-color: #e5e7eb;
}

.bg-background {
  background-color: white;
}

/* Google Maps custom styles */
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-d {
  background: none;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
  overflow: visible !important;
}

.gm-style-iw-chr {
  height: 0;
}

.gm-style .gm-style-iw-tc::after {
  background: none;
}

.gm-style .gm-style-iw-a {
  top: 0 !important;
}

.gm-style .gm-style-iw-t {
  left: 0 !important;
  bottom: 0 !important;
  transform: translateY(32vh) !important;
}

.gm-style-iw-chr > button.gm-ui-hover-effect {
  display: none !important;
}

/* Custom scrollbar styles */
.no-scrollbar {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

.product-map > div > div {
  border-radius: 12px;
}

.carousel img {
  display: flex !important;
}
